/* global maptilersdk */

// Fonction pour récupérer les coordonnées géographiques via une API (comme l'API de Mapbox, OpenCage ou Google Maps)
export const geocodeAddress = async (address) => {
    const encodedAddress = encodeURIComponent(address);
    const apiUrl = `https://api.maptiler.com/geocoding/${encodedAddress}.json?key=` + maptilersdk.config.apiKey;

    const response = await fetch(apiUrl);
    const data = await response.json();

    if (data.features && data.features.length > 0) {
        const [lon, lat] = data.features[0].center;
        return [lon, lat];
    }

    throw new Error(`Coordonnées non trouvées pour l'adresse : ${address}`);
};