document.addEventListener("DOMContentLoaded", () => {
    const container = document.querySelector(".overflow-slide");
    if (container) {
        const images = container.querySelectorAll(".imagehome");
        const startMargin = 50;
        let currentMargin = startMargin;
        const scrollSpeed = 2; // Nombre de pixels à déplacer par animation
        const intervalTime = 20; // Temps entre chaque étape d'animation (en ms)

        // Cloner les images pour permettre un défilement infini
        const cloneImages = () => {
            images.forEach((img) => {
                const clone = img.cloneNode(true);
                container.appendChild(clone);
            });
        };

        // Initialiser le clonage
        cloneImages();

        const scrollImages = () => {
            currentMargin -= scrollSpeed; // Déplace vers la gauche
            const totalWidth = container.scrollWidth / 2; // Largeur des images originales

            // Remet le défilement à zéro pour un effet infini
            if (Math.abs(currentMargin) >= totalWidth + startMargin) {
                currentMargin = startMargin;
            }

            container.style.marginLeft = `${currentMargin}px`;
        };

        // Déclenche l'animation en boucle
        setInterval(scrollImages, intervalTime);
    }
});
