import { geocodeAddress } from './geocodeAddress.js';

export const buildGeoJson = async (datasGeojson, map, callback) => {
    const elements = document.querySelectorAll('[data-club-map]');

    for (const element of elements) {
        const name = element.getAttribute('data-club-name') ?? '';
        const numCLub = element.getAttribute('data-club-numero-club') ?? '';
        const status = element.getAttribute('data-club-status') ?? '';
        const statusInfo = element.getAttribute('data-club-status-info') ?? '';
        const statusInfoClass = element.getAttribute('data-club-status-info-class') ?? '';
        const nbrMembres = element.getAttribute('data-club-numero-nbr-membres') ?? '';
        const slug = element.getAttribute('data-club-slug') ?? '';
        const lat = parseFloat(element.getAttribute('data-club-latitude') ?? '');
        const lon = parseFloat(element.getAttribute('data-club-longitude') ?? '');
        const addressParts = [
            element.getAttribute('data-club-address'),
            element.getAttribute('data-club-city'),
            element.getAttribute('data-club-postal-code'),
            element.getAttribute('data-club-postal-country') ?? 'France',
        ].filter(Boolean);
        const address = addressParts.join(', ');
        const departement = element.getAttribute('data-club-departements') ?? '';

        if (!isNaN(lat) && !isNaN(lon)) {
            datasGeojson.features.push({
                "type": "Feature",
                "properties": { name, address, numCLub, status, statusInfo, statusInfoClass, nbrMembres, departement, slug },
                "geometry": { "type": "Point", "coordinates": [lon, lat] }
            });
            continue;
        }

        try {
            const coordinates = await geocodeAddress(address);
            datasGeojson.features.push({
                "type": "Feature",
                "properties": { name, address, numCLub, status, statusInfo, statusInfoClass, nbrMembres, departement, slug },
                "geometry": { "type": "Point", "coordinates": coordinates }
            });
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(`Erreur de géocodage pour ${name}: ${error.message}`);
        }
    }

    datasGeojson.features.sort((a, b) => a.properties.name.localeCompare(b.properties.name));
    callback(map);
};
