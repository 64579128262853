/* global maptilersdk */

import { buildGeoJson } from './buildGeoJson.js';
import { createPointsList } from './createPointsList.js';
import { filterPoints } from './filterPoints.js';
import { createPopupContent } from './createPopupContent.js';

var map = '';

var datasGeojson = {
  "type": "FeatureCollection",
  "features": [
    //   {
    //     "type": "Feature",
    //     "properties": { "name": "Club", "address": "Adresse" },
    //     "geometry": { "type": "Point", "coordinates": [lng, lat] }
    //   },
  ]
};

window.addEventListener("load", () => {
  maptilersdk.config.apiKey = 'iYmBfGNJjj9Vce8NDqxM';
  if (document.getElementById('map')) {
    map = new maptilersdk.Map({
      container: 'map',
      style: "fdf26895-93d7-41e4-ae93-09cbf30b797d",
      center: [2.65, 46.73333],
      zoom: 5.5,
      fullscreenControl: false,
      geolocateControl: false,
      navigationControl: false,
      terrainControl: false,
      scaleControl: false
    });

    buildGeoJson(datasGeojson, map, buildMap);
  }
});

function buildMap(map) {
  if (document.getElementById('map')) {

    document.querySelectorAll('.search-menu-tab .tab').forEach(element => {
      element.addEventListener('click', (el) => {
        el.preventDefault();
        el.stopPropagation();

        // Vérifie si el.target est exactement l'élément ou un de ses enfants
        if (el.target !== element) return;

        if (el.target.classList.contains('current')) return;

        document.querySelector('.search-menu-tab .tab.w--current')?.classList.remove('w--current');
        document.querySelector('.search-menu-tab .tab.current')?.classList.remove('current');

        el.target.classList.add('w--current');
        el.target.classList.add('current');

        document.getElementById('map-list-container')?.classList.toggle('show-list');
      });
    });

    map.on('load', async function () {
      const imageOpen = await map.loadImage('https://cdn.prod.website-files.com/672cc3ad73edca88e8fd56fb/6760514edf60fbcd8dd03979_picto-clubs-open.png');
      map.addImage('custom-marker-open', imageOpen.data);

      const imageClosed = await map.loadImage('https://cdn.prod.website-files.com/672cc3ad73edca88e8fd56fb/676051c42b10e3faf3242edd_picto-clubs-in-project.png');
      map.addImage('custom-marker-closed', imageClosed.data);

      map.addSource('points', {
        type: 'geojson',
        data: datasGeojson,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50
      });

      map.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'points',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': '#8AA47F',
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40
          ],
          'circle-stroke-width': 2,
          'circle-stroke-color': '#FFFFFF'
        }
      });

      map.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'points',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['MundialReguler', 'DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 16
        },
        paint: {
          'text-color': '#FFFFFF'
        }
      });

      // Ajouter la couche pour les points non regroupés
      map.addLayer({
        id: 'unclustered-point',
        type: 'symbol',
        source: 'points',
        filter: ['!', ['has', 'point_count']], // Exclure les clusters
        layout: {
          'icon-image': [
            'case',
            ['==', ['get', 'status'], 'Ouvert'], // Si le statut est "open"
            'custom-marker-open', // Utiliser cette icône
            ['==', ['get', 'status'], 'En projet'], // Si le statut est "closed"
            'custom-marker-closed', // Utiliser cette icône
            'custom-marker-open' // Icône par défaut (si aucune correspondance)
          ],
          'icon-size': .5,
          'icon-anchor': 'bottom'
        }
      });

      // Ajout d'interaction : clic pour zoomer sur un cluster
      map.on('click', 'clusters', function (e) {
        const features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
        const clusterId = features[0].properties.cluster_id;
        map.getSource('points').getClusterExpansionZoom(clusterId, function (err, zoom) {
          if (err) return;
          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom
          });
        });
      });

      map.on('click', 'unclustered-point', function (e) {
        if (e.features.length > 0) {
          var coordinates = e.features[0].geometry.coordinates.slice();
          var properties = e.features[0].properties;

          new maptilersdk.Popup()
            .setLngLat(coordinates)
            .setHTML(createPopupContent(properties))
            .addTo(map);
        }
      });

      // Ajouter un effet de curseur survol des clusters
      map.on('mouseenter', 'clusters', function () {
        map.getCanvas().style.cursor = 'pointer';
      });
      map.on('mouseleave', 'clusters', function () {
        map.getCanvas().style.cursor = '';
      });

      document.getElementById('search-input').addEventListener('input', (e) => {
        filterPoints(e.target.value, datasGeojson, map);
      });

      createPointsList(datasGeojson.features, map);
    });
  }
}