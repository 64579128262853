/* global maptilersdk */
import { createPointsList } from './createPointsList.js';

export const filterPoints = async (searchText, datasGeojson, map) => {
    if (searchText.length < 3) {
        document.getElementById('map-container')?.classList.remove('zoomed');
        // Tri alphabétique des features par le champ "name"
        const sortedFeatures = [...datasGeojson.features].sort((a, b) =>
            a.properties.name.localeCompare(b.properties.name)
        );
        map.getSource('points').setData({
            type: 'FeatureCollection',
            features: sortedFeatures
        });
        map.flyTo({
            center: [2.65, 46.73333],
            zoom: 5.5
        });
        createPointsList(sortedFeatures, map);
        return; // Évite les requêtes trop courtes
    }

    const geocodingUrl = `https://api.maptiler.com/geocoding/${encodeURIComponent(searchText)}.json?key=${maptilersdk.config.apiKey}&country=fr`;

    try {
        const response = await fetch(geocodingUrl);
        const data = await response.json();

        if (data.features && data.features.length > 0) {
            const [lon, lat] = data.features[0].center;
            const radius = 50; // Rayon de recherche en kilomètres

            // Ajout de la distance dans chaque feature et filtrage
            const filteredFeatures = datasGeojson.features
                .map(feature => {
                    const [featureLon, featureLat] = feature.geometry.coordinates;
                    const distance = calculateDistance(lat, lon, featureLat, featureLon);

                    // Ajouter la distance dans les propriétés du point
                    return {
                        ...feature,
                        properties: {
                            ...feature.properties,
                            distance
                        }
                    };
                })
                .filter(feature => feature.properties.distance <= radius); // Filtrage sur la distance

            // Tri des résultats par distance décroissante
            filteredFeatures.sort((a, b) => a.properties.distance - b.properties.distance);

            // Mise à jour des données sur la carte
            map.getSource('points').setData({
                type: 'FeatureCollection',
                features: filteredFeatures
            });

            document.getElementById('map-container')?.classList.add('zoomed');
            map.flyTo({
                center: [lon, lat],
                zoom: 10
            });

            // Créer la liste des points
            createPointsList(filteredFeatures, map);
        }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Erreur lors de la recherche :", error);
    }
};

// Fonction de calcul de la distance entre deux coordonnées
function calculateDistance(lat1, lon1, lat2, lon2) {
    const R = 6371; // Rayon de la Terre en kilomètres
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
}
