
// Fonction pour créer une popin avec une iframe
export const createPopin = (url) => {
    // Création de l'overlay
    const overlay = document.createElement('div');
    overlay.classList.add('popin-overlay');

    // Création de la popin
    const popin = document.createElement('div');
    popin.classList.add('popin-content');

    // Bouton de fermeture
    const closeButton = document.createElement('button');
    closeButton.classList.add('popin-close-button');
    closeButton.innerText = 'X';

    closeButton.addEventListener('click', () => {
        document.body.removeChild(overlay);
    });

    // Création de l'iframe
    const iframe = document.createElement('iframe');
    iframe.src = url;

    // Ajout des éléments
    popin.appendChild(closeButton);
    popin.appendChild(iframe);
    overlay.appendChild(popin);
    document.body.appendChild(overlay);
};