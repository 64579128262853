window.addEventListener("load", () => {
    const paragraphs = document.querySelectorAll('[data-franchises-description]');

    // Parcours de chaque élément sélectionné
    paragraphs?.forEach(paragraph => {
        // Récupération du contenu texte brut
        const textContent = paragraph.textContent;

        // Nettoyage et ajout des <br> avant chaque tiret
        const formattedContent = textContent
            .trim()
            .replace(/-\s(?=[A-Z])/g, '<br> - ');

        // Mise à jour du contenu HTML de l'élément
        paragraph.innerHTML = formattedContent;
    });
});
