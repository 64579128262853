window.addEventListener("load", () => {
    const selectTarget = document.getElementById("menu-espace-membre-select");
    const clubsInfos = document.querySelectorAll('[data-club-infos]');
    const btnEspaceMembre = document.querySelectorAll("[data-espace-membre]");
    const btnEspaceMembreClose = document.querySelector(".popinespacemembre .popin-close-button");
    const popinEspaceMembre = document.getElementById("PopinEspaceMembre");

    // Fonction pour basculer l'affichage de la popin
    const togglePopin = () => {
        if (!popinEspaceMembre) {
            // eslint-disable-next-line no-console
            console.warn("Popin non trouvée !");
            return;
        }
        popinEspaceMembre.classList.toggle("show");

        if (popinEspaceMembre.classList.contains("show")) {
            popinEspaceMembre.focus(); // Focus sur la popin
        } else {
            // Retour du focus au premier bouton d'ouverture
            btnEspaceMembre[0]?.focus();
        }
    };

    // Initialisation des options du select
    if (selectTarget && clubsInfos) {
        clubsInfos.forEach(club => {
            const clubPortail = club.dataset.clubPortail?.trim();
            const clubFullname = club.dataset.clubFullname?.trim();
            if (!clubPortail) return;

            const option = document.createElement("option");
            option.value = clubPortail;
            option.textContent = clubFullname || "Club sans nom";
            selectTarget.appendChild(option);
        });

        selectTarget.addEventListener("change", () => {
            const selectedValue = selectTarget.value.trim();
            if (!selectedValue) return;
            window.open(selectedValue, "_blank");
        });
    }

    // Gestion des boutons d'ouverture de la popin
    if (btnEspaceMembre) {
        btnEspaceMembre.forEach(btn => {
            btn.addEventListener("click", (e) => {
                e.preventDefault();
                togglePopin();
            });
        });
    }

    // Gestion du bouton de fermeture de la popin
    if (btnEspaceMembreClose) {
        btnEspaceMembreClose.addEventListener("click", togglePopin);
    }

    // Fermeture de la popin avec la touche "Escape"
    document.addEventListener("keydown", (e) => {
        if (e.key === "Escape" && popinEspaceMembre?.classList.contains("show")) {
            togglePopin();
        }
    });
});
