document.addEventListener("DOMContentLoaded", () => {
  const tabs = document.querySelectorAll(".carddetails");

  tabs.forEach((tab) => {
    tab.addEventListener("click", () => {
      const greenCircle = tab.querySelector(".greencircle");
      const hideBlock = tab.querySelector(".hide");
      const textCard = tab.querySelector(".textintro");
      const learnMore = tab.querySelector(".learnmore");
      const imageToChange = document.querySelector(".imgtochange");
      const isActive = tab.classList.contains("active");

      tab.classList.toggle("active");

      if (isActive) {
        hideContentBlock(greenCircle, hideBlock, textCard, learnMore, tab);
      } else {
        showContentBlock(
          greenCircle,
          hideBlock,
          textCard,
          learnMore,
          tab,
          imageToChange
        );
      }

      tabs.forEach((el) => {
        if (el !== tab) {
          el.classList.remove("active");
          const greenCircle = el.querySelector(".greencircle");
          const hideBlock = el.querySelector(".hide");
          const textCard = el.querySelector(".textintro");
          const learnMore = el.querySelector(".learnmore");

          hideContentBlock(greenCircle, hideBlock, textCard, learnMore, el);
        }
      });
    });
  });
});

function getTextAndImageForNumber(number) {
  switch (number) {
    case 0:
      return {
        text: "Introduction",
        imageUrl:
          "https://cdn.prod.website-files.com/672cc3ad73edca88e8fd56fb/675c9618ecfc731bfce2b005_image%20(4).png",
      };
    case 1:
      return {
        text: "1ère mi-temps",
        imageUrl:
          "https://cdn.prod.website-files.com/672cc3ad73edca88e8fd56fb/6762de30a67a0ea7d803648c_image%20(5).png",
      };
    case 2:
      return {
        text: "2ème mi-temps",
        imageUrl:
          "https://cdn.prod.website-files.com/672cc3ad73edca88e8fd56fb/6762de2cf34d2387423e9fd4_image%20(6).png",
      };
    default:
      return {
        text: "3ème mi-temps",
        imageUrl:
          "https://cdn.prod.website-files.com/672cc3ad73edca88e8fd56fb/6762de1db630bc14740c3948_image%20(7).png",
      };
  }
}

function hideContentBlock(greenCircle, hideBlock, textCard, learnMore, el) {
  if (greenCircle) {
    greenCircle.textContent =
      greenCircle.dataset.originalText || greenCircle.textContent;
  }
  if (hideBlock) hideBlock.style.display = "none";
  if (learnMore) learnMore.style.display = "block";
  el.style.flexDirection = "row";
  el.style.alignItems = "center";
  el.style.justifyContent = "space-between";
  textCard.style.display = "block";
}
function showContentBlock(
  greenCircle,
  hideBlock,
  textCard,
  learnMore,
  tab,
  imageToChange
) {
  if (greenCircle) {
    if (!greenCircle.dataset.originalText) {
      greenCircle.dataset.originalText = greenCircle.textContent;
    }

    const number = parseInt(greenCircle.dataset.originalText.trim(), 10);
    const { text, imageUrl } = getTextAndImageForNumber(number);
    greenCircle.textContent = text;

    if (imageToChange) {
      imageToChange.src = imageUrl;
    }
  }

  if (hideBlock) {
    hideBlock.style.display = "block";
    tab.style.flexDirection = "column";
    tab.style.alignItems = "start";
    textCard.style.display = "none";
  }
  if (learnMore) learnMore.style.display = "none";
}
