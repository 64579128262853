
import { createPopin } from './createPopin.js';

window.addEventListener("load", () => {
    const btnClub = document.querySelectorAll('[data-animateur-zohoid]');
    btnClub.forEach(btn => {
        btn.addEventListener('click', (el) => {
            el.stopPropagation();
            let animateurRef = el.target.dataset.animateurZohoid;
            if (animateurRef === undefined) {
                animateurRef = el.target.parentElement.dataset.animateurZohoid;
            }
            animateurRef = encodeURIComponent(animateurRef);
            const url = `https://forms.zohopublic.eu/businesstimedeveloppement1/form/Deveniranimateur/formperma/kAHTIRXxSX_9USC_jZB7awEAM4TDSSW5EdJbuiPdXoQ?club=${el.target.dataset.animateurZohoid}`;
            createPopin(url);
        });
    });
});
