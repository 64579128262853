export const createPointsList = (features, map) => {
    const listContainer = document.getElementById('map-items-list');

    if (!listContainer) {
        // eslint-disable-next-line no-console
        console.error("Element with id 'map-items-list' not found.");
        return;
    }

    if (!Array.isArray(features)) {
        // eslint-disable-next-line no-console
        console.error("Invalid features array.");
        return;
    }

    listContainer.innerHTML = '';

    let clubsOpen = 0;
    let clubsProjet = 0;

    features.forEach(feature => {
        if (!feature.properties || !feature.geometry || !feature.geometry.coordinates) {
            // eslint-disable-next-line no-console
            console.warn("Invalid feature structure:", feature);
            return;
        }

        if (feature.properties.status.toLowerCase() === "ouvert") {
            clubsOpen++;
        } else if (feature.properties.status.toLowerCase() === "en projet") {
            clubsProjet++;
        }

        const listItem = document.createElement('li');

        const numClubItem = document.createElement('span');
        numClubItem.textContent = "Club n°" + (feature.properties.numCLub || "Unknown") + "🔔";
        numClubItem.classList.add('body-body-s');
        numClubItem.classList.add('club-number');
        listItem.appendChild(numClubItem);

        const statusClubItem = document.createElement('span');
        statusClubItem.textContent = feature.properties.statusInfo;
        statusClubItem.classList.add('body-body-s');
        statusClubItem.classList.add('club-status');
        if (feature?.properties?.statusInfoClass) {
            statusClubItem.classList.add(feature.properties.statusInfoClass);
        }
        listItem.appendChild(statusClubItem);

        const nameItem = document.createElement('span');
        nameItem.textContent = feature.properties.name || "Unknown Name";
        nameItem.classList.add('body-body-m');
        nameItem.classList.add('body-body-bold');
        nameItem.classList.add('club-name');
        listItem.appendChild(nameItem);

        const nbrMembresItem = document.createElement('span');
        nbrMembresItem.textContent = feature.properties.nbrMembres + " membres" || "0" + " membre";
        nbrMembresItem.classList.add('body-body-s');
        nbrMembresItem.classList.add('picto');
        nbrMembresItem.classList.add('picto-people');
        nbrMembresItem.classList.add('club-membres');
        listItem.appendChild(nbrMembresItem);

        const nbrDepartementItem = document.createElement('span');
        const dep = feature.properties.departement.split("-");
        const numero = dep[0];
        const nom = dep.slice(1).join("-");
        const distance = feature.properties.distance;
        nbrDepartementItem.textContent = (nom + " (" + numero + ")" + (distance ? " à " + Number(distance).toFixed(2) + " km" : "")) || "Unknown dep";
        nbrDepartementItem.classList.add('body-body-s');
        nbrDepartementItem.classList.add('picto');
        nbrDepartementItem.classList.add('picto-location');
        nbrDepartementItem.classList.add('club-departement');
        listItem.appendChild(nbrDepartementItem);

        const linkClubItem = document.createElement('a');
        const slug = feature.properties.slug;
        linkClubItem.setAttribute('href', `/clubs/${slug}`);
        linkClubItem.textContent = 'Découvrir le club';
        linkClubItem.classList.add('body-body-s');
        linkClubItem.classList.add('club-link');
        listItem.appendChild(linkClubItem);

        listItem.addEventListener('click', (e) => {
            const isMobile = () => Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.includes("Mobi");

            if (isMobile()) {
                e.preventDefault();
                const slug = feature.properties.slug;
                if (slug) {
                    window.location.href = `/clubs/${slug}`;
                } else {
                    // eslint-disable-next-line no-console
                    console.error("Slug non trouvé dans les propriétés du feature.");
                }
            } else {
                if (e.target.classList.contains('club-link')) {
                    return;
                }
                const coordinates = feature?.geometry?.coordinates;
                if (coordinates) {
                    document.getElementById('map-container')?.classList.add('zoomed');
                    map.flyTo({
                        center: coordinates,
                        zoom: 14
                    });
                } else {
                    // eslint-disable-next-line no-console
                    console.error("Coordonnées non trouvées dans la géométrie du feature.");
                }
            }
        });
        listContainer.appendChild(listItem);
    });

    if (listContainer.children.length === 0) {
        const emptyMessage = document.createElement('p');
        emptyMessage.classList.add('body-body-s');
        emptyMessage.classList.add('body-body-bold');
        emptyMessage.textContent = 'Il n\'y a pas de clubs ouverts ou en projet autour de votre recherche';
        listContainer.appendChild(emptyMessage);
    }

    document.getElementById('clubs-open').textContent = clubsOpen;
    document.getElementById('clubs-projet').textContent = clubsProjet;
};