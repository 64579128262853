import { createPopin } from './createPopin.js';

window.addEventListener("load", () => {
    const btnClub = document.querySelectorAll('[data-expert-zohoid]');
    btnClub.forEach(btn => {
        btn.addEventListener('click', (el) => {
            el.stopPropagation();
            let expertRef = el.target.dataset.expertZohoid;
            if (expertRef === undefined) {
                expertRef = el.target.parentElement.dataset.expertZohoid;
            }
            expertRef = encodeURIComponent(expertRef);
            const url = `https://forms.zohopublic.eu/businesstimedeveloppement1/form/Prospectexpert/formperma/IVRrxmmBH2uudBz3fZa0TZ82vqlsIxF556J6thKPmoo?club=${expertRef}`;
            createPopin(url);
        });
    });
});
