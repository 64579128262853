document.addEventListener("DOMContentLoaded", () => {
  const submitButton = document.getElementById("submitBtn");
  const checkbox = document.getElementById("checkboxPolitics");
  const formInputs = document.querySelectorAll(".form-input");

  if (!submitButton || !checkbox || formInputs.length === 0) return;

  const toggleSubmitButton = () => {
    const allInputsFilled = Array.from(formInputs).every(input => input.value.trim() !== "");
    const isCheckboxChecked = checkbox.checked;

    const isFormValid = allInputsFilled && isCheckboxChecked;

    submitButton.disabled = !isFormValid;
    submitButton.classList.toggle("disabled", !isFormValid);
  };

  formInputs.forEach(input => input.addEventListener("input", toggleSubmitButton));
  checkbox.addEventListener("change", toggleSubmitButton);

  // Initialize button state on page load
  toggleSubmitButton();
});
