
import { createPopin } from './createPopin.js';

window.addEventListener("load", () => {
    const btnEvent = document.querySelectorAll('[data-event-zohoid]');
    btnEvent.forEach(btn => {
        btn.addEventListener('click', (el) => {
            el.stopPropagation();
            let eventRef = el.target.dataset.eventZohoid;
            if (eventRef === undefined) {
                eventRef = el.target.parentElement.dataset.eventZohoid;
            }
            eventRef = encodeURIComponent(eventRef);
            const url = `https://forms.zohopublic.eu/businesstimedeveloppement1/form/VisitepourRencontrespcifique/formperma/ARbYsBEgRQbki7ZX1Q3gHU12_BJtOmWaAMGy1uv8Sbo?idRencontre=${eventRef}`;
            createPopin(url);
        });
    });
});
