
import { createPopin } from './createPopin.js';

window.addEventListener("load", () => {
    const btnClub = document.querySelectorAll('[data-club-zohoid]');
    btnClub.forEach(btn => {
        btn.addEventListener('click', (el) => {
            el.stopPropagation();
            let clubRef = el.target.dataset.clubZohoid;
            if (clubRef === undefined) {
                clubRef = el.target.parentElement.dataset.clubZohoid;
            }
            clubRef = encodeURIComponent(clubRef);
            const url = `https://forms.zohopublic.eu/businesstimedeveloppement1/form/DemandeVisiteur/formperma/n-fOwvWrFIwTY7M3DOIoDHVTdW8e0j21_G3zde-0ijw?club=${clubRef}`;
            createPopin(url);
        });
    });
});
